

















// @ts-ignore
import { VaultItem } from 'vaultifier';
import Vue, { PropType } from 'vue'
import RawJson from './RawJson.vue';

export default Vue.extend({
  components: {
    RawJson,
  },
  props: {
    item: {
      type: Object as PropType<VaultItem>,
    }
  },
  computed: {
    meta(): any {
      const copy = { ...this.item };
      delete copy.content;
      delete copy.raw;

      return copy;
    },
    isEncrypted(): boolean {
      return this.item.isEncrypted;
    }
  }
});
